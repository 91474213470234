import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MenuSingleItem from '../../MenuSingleItem'
import MenuFakeItem from '../../MenuFakeItem'
import Indicator from '../../../Indicator'
import { Text, IconWrapper, ProBadge } from '../../../_styles'
import proBadgeUrl from './_assets/pro.png'

interface SubItemProps {
  title: string
  link: string
  disabled?: boolean
  active?: boolean
  isPro?: boolean
  badge?: (state: any) => boolean | number
}

const SubItem: FC<SubItemProps> = props => {
  const { title, disabled, active, link, badge, isPro } = props

  const { t } = useTranslation()

  const counter = badge ? useSelector(badge) : false

  const content = (
    <>
      <IconWrapper />
      <Text>
        {t(title)} <Indicator value={counter} />
        {isPro && <ProBadge src={proBadgeUrl} />}
      </Text>
    </>
  )

  return disabled ? (
    <MenuFakeItem>{content}</MenuFakeItem>
  ) : (
    <MenuSingleItem isActive={active ?? false} link={link}>
      {content}
    </MenuSingleItem>
  )
}

export default SubItem
